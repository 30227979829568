<template id="V-sign">
  <div class="container">
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <h1 class="text-primary">Validador de Firmas</h1>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col>
        <div class="mt-5">
          Valida que la Firma Electrónica corresponda a la huella digital
          de un documento y que éste no haya sido alterado después de su firma.
          <br>
          Este validador <b>no aplica</b> para las firmas realizadas bajo el estándar <b>PAdES</b>.
          Para más información visite el siguiente
          <a href="https://ayuda.digitafirma.com/collection/55-funcionalidades-avanzadas">enlace</a>.
        </div>
      </vs-col>
    </vs-row>

    <!-- Form -->
    <div v-if="!result">

      <div class="mt-5">
        <b>Archivo</b>
        <input id="fileInput" type="file" ref="fileInput" class="hidden"  @change="handleClick">
        <div id="file"
          @click="$refs.fileInput.click()"
          @drop="handleDrop"
          @dragover="handleDragover"
          @dragenter="handleDragover"
          class="px-8 py-8  cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl">
          <feather-icon icon="UploadIcon" svgClasses="h-16 w-16 stroke-current text-grey" class="block"/>
          <span class="block">Arrastra o selecciona tu archivo </span>
          <span v-if="file"><b>{{file.name}}</b></span>
        </div>
      </div>

      <div class="mt-5">
        <b>Firma</b>
        <vs-textarea label="Firma (base64)" v-model="signature"/>
      </div>

      <div class="mt-5">
        <b>Algoritmo de digestión</b>
        <v-select v-model="algorithm"  :options="algos" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
      </div>

      <div class="mt-5">
        <b>Certificado</b>
        <input id="certInput" type="file" ref="certInput" class="hidden"  @change="handleClick">
        <div id="cert"
             @click="$refs.certInput.click()"
             @drop="handleDrop"
             @dragover="handleDragover"
             @dragenter="handleDragover"
             class="px-8 py-8  cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl">
          <feather-icon icon="UploadIcon" svgClasses="h-16 w-16 stroke-current text-grey" class="block"/>
          <span class="block">Arrastra o selecciona tu certificado </span>
          <span v-if="certificate"><b>{{certificate.name}}</b></span>
        </div>
      </div>

      <vs-button v-if="!loading" type="filled" @click.prevent="validateSignature" class="mt-5 block">Validar</vs-button>
      <vs-progress class="mt-5" v-if="loading" indeterminate color="primary">Validando ...</vs-progress>

    </div>

    <!-- Validation Results -->
    <transition name="fade">
      <div class="container mt-5" id="results" v-if="result" ref="content">
        <!--todo: make component-->
        <div class="flex flex-wrap items-center justify-between">
          <h2 class="mb-base mr-3 text-primary">Resultado de tu validación</h2>
          <div class="flex items-center">
            <vs-button class="mb-base mr-3 no-print" color="success" @click="printResult" type="filled">Imprimir reporte</vs-button>
          </div>
        </div>

        <!--Detalles del Documento-->
        <doc-details :name="result['filename']" :hash="result['hash']"/>

        <!-- Firmantes -->
        <vx-card id="signers">
          <vs-list-header
            icon="fingerprint"
            title="Firmantes"
            :color="result['signerResults'][0]['signatureValid']
                  ? 'success'
                  : 'danger'"
          >
          </vs-list-header>
          <signer v-for="signer in result['signerResults']" :key="signer['signerName']"
                  :name="signer['signerName']"
                  :digest-alg="signer['digestAlgorithm']"
                  :is-sig-valid="signer['signatureValid']"
                  :sig-date="signer['signatureDateTime']"
                  :cert="signer['certResult']">
          </signer>

        </vx-card>
        <div class="vx-row no-print">
          <vs-button class="mt-5 block mr-2" @click="restore" type="filled">Validar otra firma</vs-button>
        </div>
      </div>
    </transition>

  </div>
</template>
<script>
import ValidatorService from '../service/validatorService'
import vSelect from 'vue-select'

export default {

  components: {
    'v-select': vSelect
  },
  data () {
    return {
      algorithm: {label: 'SHA-256', value: 'SHA256'},
      algos: [
        {
          label: 'SHA-256',
          value: 'SHA256'
        },
        {
          label: 'MD5',
          value: 'MD5'
        },
        {
          label: 'SHA-512',
          value: 'SHA512'
        },
        {
          label: 'SHA-1',
          value: 'SHA1'
        }
      ],
      certificate: null,
      file: null,
      signature: '',
      result: null,
      error: false,
      loading: false
    }
  },
  methods: {
    handleDragover (e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop (e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.alert('solo se permite un archivo')
        return
      }
      const rawFile = files[0] // only use files[0]
      let cert = false
      if (e.currentTarget.id === 'cert') {
        cert = true
        if (!this.isCer(rawFile)) {
          this.alert('Solo se admiten archivos .cert, cer, crt')
          return false
        }
      }

      this.uploadFile(rawFile, cert)
    },
    isCer (file) {
      return /\.(cer|cert|crt)$/.test(file.name)
    },
    handleClick (e) {
      const files = e.target.files
      const rawFile = files[0]
      if (!rawFile) return
      const cerFile = (e.target.id === 'certInput')
      this.uploadFile(rawFile, cerFile)
    },
    uploadFile (file, isCer) {
      isCer ? this.certificate = file : this.file = file
    },
    restore () {
      this.certificate = null
      this.signature = ''
      this.result = null
      this.error = false
      this.algorithm = {label: 'SHA-256', value: 'SHA256'}
      this.file = null
    },
    async validateSignature () {

      if (!this.validateForm()) return

      const vm = this
      this.loading = true

      await ValidatorService.validateSignature(this.file, this.signature, this.algorithm.value, this.certificate)
        .then(function (response) {
          vm.restore()
          vm.result = response.data
        })
        .catch(function (error) {
          vm.error = true
          console.log(error.response.data)
          vm.alert(error.response.data['description'])
        })
        .finally(function () {
          vm.loading = false
        })
    },
    alert (error) {
      this.$vs.dialog({
        color:'warning',
        title: 'Problema de validación',
        acceptText: 'Aceptar',
        text: `${error}`
      })
    },
    printResult () {
      window.print()
    },
    validateForm () {
      if (!this.file || !this.certificate || !this.signature) {
        this.alert('Faltan datos solicitados')
        return false
      }
      return true
    }
  }
}
</script>

<style>
  h6 {margin-bottom:5px;}
</style>
